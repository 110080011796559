import { KeyStringAnyValue } from '../typings';
import { tenantIdHeaderKey } from './org';

/**
 *
 * @param apiVersion - api version to set on the http header:
 * EX: 1.0 | 2.0
 * @returns an http config with an ApiVersion header
 */
export const setApiConfigVersion = (apiVersion: string) => {
  return {
    headers: {
      ApiVersion: apiVersion
    }
  };
};
/**
 *
 * @param config axios http config
 * @param headers any custom headers to add to existing config headers
 * @returns a new http configuration with new headers
 */
export const setHttpHeaders = (config: KeyStringAnyValue, headers: KeyStringAnyValue) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      ...headers
    }
  };
};

/**
 *
 * @param config axios http config
 * @param orgId a specific org id string
 * @returns a new http configuration with a specified org_id header
 */
export const setOrgIdHeader = (config: KeyStringAnyValue, orgId: string | undefined) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      [tenantIdHeaderKey]: orgId
    }
  };
};
