import React, { useState } from 'react';
import FormHeader from '../../../components/FormHeader';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ErrorToast } from '../../../components/Toaster';
import CustomerLookupApi from '../api/CustomerLookupApi';
import { DimCustomer } from '../models/DimCustomer';
import { CompositeRecord } from '../models/CompositeRecord';
import { MatchedRecord } from '../models/MatchedRecord';
import { GrmIds } from '../models/GRMIds';
import SearchForm from './CustomerLookup/SearchForm';
import CustomerResultsGrid from './CustomerLookup/CustomerResultsGrid';
import GrmResultsContainer from './CustomerLookup/GrmResultsContainer';

// Shared constants
export const LABELS = {
  unknownErrorText: 'Unknown error',
  grmContactLabel: 'GRM Contact ID',
  emailPrimaryLabel: 'Email Primary',
  phonePrimaryLabel: 'Phone Primary',
  addressPrimaryLabel: 'Address Primary',
  largeDataGridClass: 'large-data-grid'
};

export const OVERLAY_BACKGROUND = 'rgba(255, 255, 255, 0.7)';
export const DEFAULT_PAGE_SIZE = 5;

const CustomerLookup: React.FunctionComponent = () => {
  // Core state
  const [searchParams, setSearchParams] = useState({
    field: 'FullName',
    operator: 'Equals',
    value: ''
  });

  const [dimCustomerResults, setDimCustomerResults] = useState<DimCustomer[]>([]);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [grmIds, setGrmIds] = useState<GrmIds[]>([]);
  const [compositeRecord, setCompositeRecord] = useState<CompositeRecord[]>([]);
  const [matchedRecords, setMatchedRecords] = useState<MatchedRecord[]>([]);

  // Loading states
  const [loadingDimCustomerResults, setLoadingDimCustomerResults] = useState(false);
  const [loadingGrmIds, setLoadingGrmIds] = useState(false);
  const [loadingCompositeRecord, setLoadingCompositeRecord] = useState(false);
  const [loadingMatchedRecords, setLoadingMatchedRecords] = useState(false);

  const location = useLocation();

  // Page size states
  const [currentDimCustomerPageSize, setCurrentDimCustomerPageSize] = useState(DEFAULT_PAGE_SIZE);

  // Search handler
  const handleSearch = async () => {
    if (!searchParams.value) return;

    try {
      setLoadingDimCustomerResults(true);

      setSelectedRow(null);
      setDimCustomerResults([]);
      setGrmIds([]);
      setCompositeRecord([]);
      setMatchedRecords([]);

      // Small delay to ensure UI reflects cleared state
      await new Promise(resolve => setTimeout(resolve, 50));

      const params = new URLSearchParams(location.search);
      const tenant = params.get('tenant') as string;

      // Execute search only after clearing previous data
      CustomerLookupApi.getDimCustomerRecords(tenant, searchParams.field, searchParams.operator, searchParams.value)
        .then(data => {
          if (Array.isArray(data)) {
            setDimCustomerResults(data);
          } else {
            console.warn('Unexpected data format received:');
            setDimCustomerResults([]);
          }
        })
        .catch(error => {
          ErrorToast('Cannot Search Right now', error.message || LABELS.unknownErrorText);
          setDimCustomerResults([]);
        })
        .finally(() => {
          setLoadingDimCustomerResults(false);
        });
    } catch (error: any) {
      ErrorToast('Cannot Search Right now', error.message || LABELS.unknownErrorText);
      setDimCustomerResults([]);
      setLoadingDimCustomerResults(false);
    }
  };

  const handleRowClick = async (params: any) => {
    const row = params.row;
    setSelectedRow(row.grmcontactid);

    setGrmIds([]);
    setCompositeRecord([]);
    setMatchedRecords([]);
    setLoadingGrmIds(true);
    setLoadingCompositeRecord(true);
    setLoadingMatchedRecords(true);

    // Load GRM IDs
    CustomerLookupApi.getGrmIds(row.tenantId, row.grmcontactid)
      .then(data => {
        setGrmIds(data);
      })
      .catch(error => {
        ErrorToast('Error Fetching GRM IDs', error.message || LABELS.unknownErrorText);
      })
      .finally(() => {
        setLoadingGrmIds(false);
      });

    // Load Composite Records
    CustomerLookupApi.getCompositeRecords(row.tenantId, row.grmcontactid)
      .then(data => {
        setCompositeRecord(data);
      })
      .catch(error => {
        ErrorToast('Error Fetching Composite Records', error.message || LABELS.unknownErrorText);
      })
      .finally(() => {
        setLoadingCompositeRecord(false);
      });

    // Load Matched Records
    CustomerLookupApi.getMatchedRecords(row.tenantId, row.grmcontactid)
      .then(data => {
        setMatchedRecords(data);
      })
      .catch(error => {
        ErrorToast('Error Fetching Matched Records', error.message || LABELS.unknownErrorText);
      })
      .finally(() => {
        setLoadingMatchedRecords(false);
      });
  };

  return (
    <Box p={4}>
      <FormHeader id="customer-lookup" title="Customer Lookup" description="Search for customers and select a result to see contact details." />
      {/* Search Form Component */}
      <SearchForm searchParams={searchParams} setSearchParams={setSearchParams} onSearch={handleSearch} />

      {/* Main Results Grid */}
      <CustomerResultsGrid
        results={dimCustomerResults}
        loading={loadingDimCustomerResults}
        selectedRow={selectedRow}
        onRowClick={handleRowClick}
        pageSize={currentDimCustomerPageSize}
        setPageSize={setCurrentDimCustomerPageSize}
      />

      {/* GRM Results Section */}
      {(grmIds.length > 0 ||
        compositeRecord.length > 0 ||
        matchedRecords.length > 0 ||
        loadingGrmIds ||
        loadingCompositeRecord ||
        loadingMatchedRecords) && (
        <GrmResultsContainer
          grmIds={grmIds}
          compositeRecord={compositeRecord}
          matchedRecords={matchedRecords}
          loadingGrmIds={loadingGrmIds}
          loadingCompositeRecord={loadingCompositeRecord}
          loadingMatchedRecords={loadingMatchedRecords}
        />
      )}
    </Box>
  );
};

export default CustomerLookup;
