import React from 'react';

interface LogoProps {
  height?: string | number;
  width?: string | number;
  className?: string;
}

const AQLogo: React.FC<LogoProps> = ({ height = '35', width = '27', className = '' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 68 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      aria-label="Affinaquest Logo"
    >
      <image
        id="image0"
        width="68"
        height="87"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABXCAYAAAC9UeOHAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAABYlAAAWJQFJUiTwAAAS+ElEQVR42u1ca3QVVZbeZ5+6uQkkCAmC8hChQUdEFIggKDAR
REEHbJU3qKAtLtRWRqe1255ZWd2zWnF61OWLSY+DIj6Q1epI2yi2L7oVpQVtFQeagLwFgTyAkNxb
95y950e9TtWtJCQmIGvlrFWrzq26t+qe7+5v72/vc+oCtLW21tbaWvObOB43YWYJADcBQLfmXuOt
FRs3jZ/Uf9nxAqa1ATmHmVPczEZEmeuvfrm8f4eHC1v7u+JxwmQaACSb++EtmyrUF5/t7U15cMVJ
DwgzdwCA6d/nGm++vtnWimXCgrknPSAAUAIAfZr7YdJsv7z0iyRaAjAhxwzu/ejAkxoQpfStWpMk
YmBmYG7a5/+0sjxVezSTlBJBogBEnHXSAlJbW9tTKR6nNYNWBFozEBF44DTWtCb96rINOSgRpBQg
JYKUcuawAY92PSkBIbLu1IrQAYNAKXKAMbaGLGfr5sq6Lz/fl2NZAiyJDiCW6CYpOf6kA6S6urqT
yuiJpNkBQzOQdqzEe621A5RWBKQJSHvgOOi88cpGYAZEiYBSgLQEoERISHEDtJKGajVAtJ0zVivq
51gGA6kwGD44xnEHHAatGQ5V1qXefXtLQiKC5dEFHeqghReWDP7vwa3xva3WuCgzi/37auYJFEDE
IFAAAYBAAUIACCGA3L1AAQLYPccghAAEgI8/2qkPV9vtpSWcY8ZnEUV7QJgLAOtb+ru3ioXs2XN0
IGka7v/6ikBT1BrYp4u/dzeliV998WvDkYpQH6UAC8W08cMe69DS371VLEQpNYUFtPN+cSEECOJg
b1hD3H7v7iO1WzZXtEdEEAiA3jW8vmMthSJRcB0ALG7J797iFsLMOZyhW52IwuavHnKi0U2pwIJe
WfY1S0SwLAGWhWDJoO9bjIWQkHLO5OHL81ry+7e4hXyz6eB1hKIQWQCbvz65rxuwDhYAR6rS6S/W
f5uD0vAbKFwr8fyQ00cpRkA7dQEAfPyDBGTDhg05tqabkAQwOlExGAyHqVMPOOs/+dY+XJ0ukFJk
AYFCAAgA9PooEC2e3ZKAtChliLqepxRdrDwR5jpUZW4ubfz3KPYFm7JJvbuqXDoCzKEHen0LAS10
aOP1pQCUOGny+OWnttQYWtRCOJOZzQKSTAACDYcaoQ554RUZyLUAEgJ2bqu2931bk+uFWuFag4jQ
Bc0+QjdL8hQAeLIlxtBiFvLZZ0dOVYqvc4SVKbqczVSpQcg1chxNsPbDnQpAIKITWqVEMPueMJMy
cK6ICDIhrm+pcbQYIOnainFaU7dQzuKBYtDC1B3KiDh1daru0092t5MS/cgiXYp4NLEs4dPHkiI4
hzj0lmmvXPmDAYSZBZOYrxUJJy8JW4eOWkdUsGmCv63fW8nElpOvCF+MoZv2x/URA8EmUNz5gwHk
o9XbLlGKhkcH7msRM1eJAUdl6EDVgdrfBAPGbCBCajWgjlEaGH77jf973gkHhJmFneb7tCKhIhHF
o0VwzBBqpn9hePv2n41YJBP4F48SnviyPKpIjzrCP2e+T1qYbyF+7xLj9wbkj69tPJc0jdJxTtOk
juFslQ5opRQza3pSCMEW4hIpBUj0VKlwnSr6Dta3EAyA8SgmpJgxe/Zz7U8oIGjJa5Sm/FB0iexJ
BVHFB8M7p3njoKHdPwEAyM+0exUtsavewRvJnQmE5ddLsEu3/FN/fMIAKStbl9CKbzMjhorJT1Qk
q41Q6xEhBAMALPrjVVUJIX5vRhMZ6qNBHTSqaOiCgiAsnlNa+n7uCQGkS8f2E0npLqGaqWEdZCZz
Ojb936dRrQp9Icx5USDqqJWg4VS9PCfQIk41zS0kjU5VpZrtXJutVMvK1iVIqZ8wAghyVCMTA7gK
NKxQIegzgwABAhlQiD9cOrrvLvO66dwOG9rzoQ+EEGO8gpKR8of6fuEI3b5zL5lAMR0APj2uFlKQ
mzxHaRqpXL+gIrTxoorKpohHK5VRibLodZ99tiRlIT/riy8ZRBNLOjmMNI9bCBb6BWjPgq4rXfBW
s6Y9m08ZrWdqze2iESS8r0d7aIKMok+LTjvwZdylK1W716SE/X5xGQOqePVVdMMvovBBQvQAw56J
vMQ1xw2Q5U9vKFRaT21QgcYVkHVQbGatXyguLs7EXX/p0suPJhL4QtQqnMgSk894r61AyFk58obj
BshhOzVGKz4jbp5FKdORkp/LRM4f4ZzUMw3fJecZKbHGS/M9AKyoWrXCytV4zyUP//ufhx4XQFjT
fK1JUJzmiKVQ1JJ46cSJxbUN3WPhE5d9JSWukRiW7mjQJasAHZH6ApueBTcZkKcfXtNXKRodq0rr
Se0jPiWFGVp6LPeyJD7jOVSM0CXwL0FYRpNGiCAtMfV/Hv+wSYt0mhx2U4R3AZAgCkJf7B4FCAjX
QN2Czse6YPOxhUSW70iLdggQvQQKQAQ3ZMeE4/hCUmdbyUkAsKhVLKTst+s6a6UmBKl9A1aisqMN
aWatYMmUKVP0sdyv9D9LDiKK53xaYJgiJnVQZtMIJYIleTYzH/O0Z5MAqak9OkEp6h0UfhoBxaWO
k8gxKEW7KKfj6025Z9LKfVFayHG08Oji9X0ahSIQXrD4qbUXtzggkycvlxnN88z5k7j5lfg5F+3N
6b4xZ07v6qYAck/piE1Sije9X9/LgqXMSv8dwWZlRaA8K5GY0+KADP5R4WBSNIQaEFuNUIiUhKea
AobXJMjFUiLHaY5opImjjpTiuiVPrC1qUUDSjNM0cTK7zpFd74gJs6A1//X224d+3RxACpP5b0kp
tppCDM3oEkOdUDRC7JAsyJ3aYoAsWLAmj7T+SXh+xc1h3FJhuDIWzmm0JmDmR5oDBgDA9f9y/lFL
iiVm+m8ZFLEi1PHLB6EpUDF3xYo97Rq71zGF3Vw6MkMDFKAAAGN+xcw4IWta0tmDYGAS2yjT/r24
a7//PltHKjdNYQ03a5W5/5oZA+Nn4SzxqoVwPwDmxk6ACyPrNbJhZ8YPQAgxRB+tHAoAH3wvC7nj
jpVJ1nRDrCI1lzSo7LkXvzpG8Mo9pcUHo9detWpfF/voljKJ8DwzlSgF7zz/9Oe/WbmyPGtNq0ha
5ShwZUxx2Y8uaNRHstStRJAJeWNj420UkJx06kLlrvWIq4Z5ZcHo3EtAG53KaP20eU1mFh+u3jY1
ATWfgeC5RCAIGIi5HTH9fO+26i8XPfLpxaZ+mDevOAMJT7lm5zFWpIIW2kuvUC3Gv/36pgaVa6OA
aE7M0pqsBlP7hh3qn7VVUu5db82aXd3XfbxnEZBYAgDdwVt0R87CO2fjs1ir9x578MNfP7xgjb/c
Ib9j8m0pxfZjiS5WjEiTErsQQoPLOhsE5PZpr3fTiq8J5yUN1D3MzNZP9GBJaakgAIAvP98/IS/H
+oCY5jFzkhmAfBDAB4aIgZhzNMEvVIG9+lf3rj4PAGDKlAG2JcXSyCBjQQmXGYP3WJY1s6ExN+hU
VY6+ArU4FcDMF8B1rIYzE0bpEAQAAiAJICG+SxZayzeuPVxknZJemErpm5gIwAQgawsBJDTwhcxq
/f0LVi3MHEo9lJOTXKx15g4hsSMajjRrcrx+ZztwzXs7xoy4tNe7TbIQZhak4DalvIKxu4Iw+jou
7Xf3DGLx3LmDxySLMh8QibngDpq8wRM4VCEINheNABwGBkgw8S8pmVj9zns72suEfDck0uqhTrxI
Q9DIc6GeZZ31AnLz9BUXKKUHm4PXWWAwUMSfeMcECH3t9P6DEOANZhjATIIZnIW6HhDmoI2NvOPe
e4Nzg6S2P9z41Xd5sp6IEgi0GIC85FDAVWtXbz+zSYBk0ql/1sa8SlwR2Ys2ZkHZm/3v1aeT7Nnr
lCuY2ALfabJDFyDXd0RB8IAIzkWthYg77tl1eEJtjR2INKPg7M/TmCJOGoJNIkhLdsAca8YxAzJt
3Es9teKxDc3W118NIyACGDj4NGAAIGZn8ABA5Lz2Buw5VGIA8BwsMTCwu+TbOxYGz7Y1fLO1KjR7
522W73BNDeJYDBo5kECYsnz5hpxjAoQTfLXWdFq9SxkMDRJXXe9UmAt9zypyBwJ+5Ihxmi4t3PPE
BgBgWI1BLZduO76pAiEgoEUk/fcGb8kwEB5tEhaefU6fossaBWTy5OU5KqNv8RyoigoyHZ65D4Hj
vvfcgV3BSqChLzieHh4AHpWy6OO9h0P+hJjhaI0NO7ZVh6c2I1TxgLFCAs0/nhQJmNMoIJkD6RFa
wT+YYis0Pakisj1iQUIADLukZ8RhGlZBgVWY58l4vx+WnQgNTGFr8cDb9NV+QITAqZoO1nWqlqlH
rGhkkleWl1f0aBAQRTSbtLa81YJUnxINLbYNtr5nF0Gnwjx3cBQGIY4yMZQgw6qiVCMOzu/adQiq
KlNhWhi+wstvLA8I9FYuehQTuUQ8q15ALh++vFBrPVOZyxdiUnvvGIXOOeCMHtMbQuHVcJ7RX5oN
fxG2mMCCIBxhDIsCUDbB39Z/aww6iCZOPdUFxog0aEXWr0kxt6KiokMsIKSOzNGKkqHl1w3USpVv
Pc6xrt0KoO/ZRc6gAQJgYszdjyjmQMP5jAGmexwM8eZ+pnzjAVBKA/qPkRgLbIzVi340QqM4jQiW
xH51NYlRWYAMGVLWTpGeEfu0QiO5DLngDR3RA5K5VhA5zF/X9ROmMPPOQ8jXxFhPDO28z1ZVpuDr
L77zHy6Ke3oipFwtEQDk9i0Lbs4CJC/Do3SGB6lQxMh+hKO+5ZXJZAIuKekdylNMUQURcCIAfAYs
7iOiLZ5OITajS305j+PgP12z23WgwQqA7L6/hNPvexQDIUbt35/qZwDCghTdqIkE+flJfGpPUU3i
1kzPPb8LFBblhRQnGb8uxUQWZq5j5icwc3TsnPnFC1FkRjHzM64/zpbyHOdvALaWV8L2rZXGTF44
mpgrBaTR9ygmLeyESHf5gAzp+3gfIrrSzEXMPEXVO6MfhN6x4/s60NZj4jGR5UtSMGHq9ef/dOb8
kVUAAPMWjNpbnep0q1J0LTFVmJ8jI1z7/sdz2kSw6g/lxuRVpKKGMX3LPC7AQpheW1l7hmMhWtyq
FOdnrQ0zF+Yb+Uy0MnZmn0IYdGG3ePOOvCYCLQAfKCjKGzZ59nkfeOvLvFZaOsD++a/+8TUL4Gxm
fpEYlE83ACAXdfI3V5N8fQD2f1fjV838vMXsG6uiTUHnAtgpI/EqHNRzYTdNNC1Mj5i5l0hq71Xd
iQAmzx4QFlcUtQpfppcj6Inj8vv+W0lJ7xQ00H7xwNgKq8O42Uw4izTtD8l83ycFFlhzJA2r39kW
OMvgsdbstSSY3XeB64K5+fnjtaYe0QGHwanvHEPX09vDsBFhZRqIJ985ZpjFokKZP/jS8WevFCVC
wTG00lJB//HU5S/XaRpBxK+FQ7hJTee+f3lvm1sIMkqHkcFbsf7FXZ2ECHh5Sf+Xe53Z8UEUUBEs
djE0hhlZYh4TGzH6TEjmWQYY7BV1vH55AnDM8JFnzB9Q0qXmWICItt8t+fHWSltNZqLZTLAn6qQ9
y9z37RH460e7HGFmzMsEYkwEz9x4K6VdYOyUqtq968g6v2o0dfzzF+3cfvjBI4fSoxhYBEsbYpY7
uCW5hIWw7M0ZcFq3fMjYBLatIWNrSKcVpFNakYIXM0D3DhjQZV9zgIhr1172Qj8Q8EDGVtfaaQ22
rSBja7DTGuyMhiFDe8CDT1wBEkV4KUbM01lCAGhi+3C1vWLXnup/LS7uvsnXIS+/OeuTWVPPuer0
Hh1+KaWoi6VMhDoDh5wOPXudEpPmw1Yp+ZqXfl80pyXBAAB45U8zy9MVBdcrTXOIqSZIB5zvsG7t
Hti145D78IAxXeGLsaCfsalm5/ZDd3c9vf3k4uLumwDqqStOm/BS8ea/H3i85rB9kVdMhhgreezp
iTBqbG+w08qzEM7YVJaTl39vUZE43JJAxLWxQ8rOyGjxXxlbXWHbWmRsDbatYdqN58M9949yit0o
AL3Ct2sZRMyVB+tWbSuv+ulFo3uWm9eMLRAtWzl93QXn9hrXtVvBrxMJWatihFnPXh1hyLDuQRIG
vFtIMe207vl3HQ8wAADeWT9v5wG749XE4jZm2OdFovdXbYVUWjn+AQPHKVFAKqX279heNXfR79r/
UxSMei3EbJNKnhu2b+/hxQf31/Y3LWX+3RfBnT+7GJSijJ3Rz2mduK9DB3Gwseu1Vhvwoyd7ijr7
gXRGz9SaYOHj42HSlP7OvDM4UyWVB+s+3vr3qhuHjeyx+XvdrOyWdYmRA5567KzOvz3Up+NCHtjz
Ud65vZqZuTyT4ZKmLFlq7da380M39yp4cPeMq15iJudPndIpVbNl48E7ysrWJVr0ZhMufuay8894
bP2sSctqtNKPM3PnEw1AXOuRfKhf38KHlm/ZfDBVWVH31v99vr9fq93s7ltWdH7q4bUjT/SgG2+3
JFa8uuHS0lI+Xv/E1dbaWltra22trbW1ttbW2lortv8HvY6c1ExfEm8AAAAldEVYdGRhdGU6Y3Jl
YXRlADIwMjItMDMtMzBUMTg6NTQ6MDUrMDA6MDA/iGPbAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIy
LTAzLTMwVDE4OjU0OjA1KzAwOjAwTtXbZwAAAABJRU5ErkJggg=="
      />
    </svg>
  );
};

export default AQLogo;
