import { useMemo } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { OVERLAY_BACKGROUND, DEFAULT_PAGE_SIZE } from '../../CustomerLookup';

interface DataGridWrapperProps<T> {
  data: T[];
  columns: GridColDef[];
  loading: boolean;
  pageSize: number;
  setPageSize: (size: number) => void;
  getRowId: (row: T) => string | number;
  customClassName?: string;
  onRowClick?: (params: any) => void;
  selectedRow?: string | null;
}

// Simplified utility functions
const isRowSelected = <T extends Record<string, any>>(row: T, selectedRow: string | null | undefined): boolean => {
  if (!selectedRow) return false;
  return Object.values(row).some(value => value !== null && value !== undefined && String(value) === String(selectedRow));
};

const createLoadingOverlay = (overlayBg: string) => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      backgroundColor: overlayBg,
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1
    }}
  >
    <CircularProgress />
  </Box>
);

function DataGridWrapper<T extends Record<string, any>>({
  data,
  columns,
  loading,
  pageSize,
  setPageSize,
  getRowId,
  customClassName,
  onRowClick,
  selectedRow
}: Readonly<DataGridWrapperProps<T>>) {
  const gridState = useMemo(
    () => ({
      pagination: {
        paginationModel: { page: 0, pageSize }
      }
    }),
    [pageSize]
  );

  // Row class name handler
  const getRowClassName = (params: GridRowParams<T>) => {
    return isRowSelected(params.row, selectedRow) ? 'selected-row' : '';
  };

  // Style configurations
  const containerStyles = {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  };

  const gridStyles = {
    '& .selected-row': onRowClick
      ? {
          backgroundColor: '#f0f0f0',
          '&:hover': {
            backgroundColor: '#e0e0e0'
          }
        }
      : {},
    '& .MuiDataGrid-row:hover': onRowClick
      ? {
          cursor: 'pointer'
        }
      : {},
    border: 'none',
    '& .MuiDataGrid-virtualScroller': {
      overflow: 'visible'
    },
    '& .MuiDataGrid-main': {
      overflow: 'visible'
    }
  };

  // Handle pagination changes
  const handlePaginationModelChange = (model: any) => {
    if (model.pageSize !== pageSize) {
      setPageSize(model.pageSize);
    }
  };

  return (
    <Box className={`data-grid-container ${customClassName ?? ''}`} sx={containerStyles}>
      {loading && createLoadingOverlay(OVERLAY_BACKGROUND)}
      <DataGrid
        rows={data}
        columns={columns}
        loading={loading}
        getRowId={getRowId}
        initialState={gridState}
        pageSizeOptions={[5, 10, 25]}
        hideFooter={data.length <= DEFAULT_PAGE_SIZE}
        onPaginationModelChange={handlePaginationModelChange}
        autoHeight
        disableRowSelectionOnClick={!!onRowClick}
        onRowClick={onRowClick}
        getRowClassName={getRowClassName}
        sx={gridStyles}
      />
    </Box>
  );
}

export default DataGridWrapper;
