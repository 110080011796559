import React from 'react';
import { Grid } from '@mui/material';
import PageContentHeading from '../styled/PageContentHeading';
import PageContentDescription from '../styled/PageContentDescription';

interface SftpConfigurationHeaderProps {
  title: string;
  description?: string;
  id: string;
  children?: any;
}

export const FormHeader: React.FunctionComponent<SftpConfigurationHeaderProps> = ({
  title,
  description,
  id,
  children
}: SftpConfigurationHeaderProps) => {
  return (
    <>
      <Grid item xs={12} sm={children !== undefined ? 'auto' : 12} id={`${id}-header`}>
        <PageContentHeading variant="h5" id={`${id}-header`} className={`${id}-header_title`}>
          {title}
        </PageContentHeading>
        {description && <PageContentDescription className={`${id}-header_description`}>{description}</PageContentDescription>}
      </Grid>
      {children}
    </>
  );
};

export default FormHeader;
