import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { GrmIds } from '../../models/GRMIds';
import { LABELS } from '../CustomerLookup';
import DataGridWrapper from './common/DataGridWrapper';

interface GrmIdsGridProps {
  data: GrmIds[];
  loading: boolean;
  pageSize: number;
  setPageSize: (size: number) => void;
}

const GrmIdsGrid: React.FC<GrmIdsGridProps> = ({ data, loading, pageSize, setPageSize }) => {
  const columns: GridColDef[] = [
    { field: 'grmAccountId', headerName: 'GRM Account ID', flex: 1, renderHeader: () => <strong>GRM Account ID</strong> },
    { field: 'grmContactId', headerName: LABELS.grmContactLabel, flex: 1, renderHeader: () => <strong>{LABELS.grmContactLabel}</strong> },
    { field: 'grmHouseholdId', headerName: 'GRM Household ID', flex: 1, renderHeader: () => <strong>GRM Household ID</strong> }
  ];

  return (
    <DataGridWrapper
      data={data}
      columns={columns}
      loading={loading}
      pageSize={pageSize}
      setPageSize={setPageSize}
      getRowId={row => row.grmContactId}
    />
  );
};

export default GrmIdsGrid;
