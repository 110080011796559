import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import SsbModule, { ModuleSubPath } from '../../models/module';
import { styled } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import history from '../../history';
import NavigatorListItem from './NavigatorListItem';
import NavigatorNestedListItem from './NavigatorNestedListItem';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { setIsDrawerOpenDesktop, setIsDrawerOpenMobile } from '../../actions';
import AQLogo from '../logos/AQLogo';
import AQLogoFull from '../logos/AQLogoFull';
import DrawerComponent from './DrawerComponent';
import DrawerMobileContext from './context/drawerMobileContext';
import { canViewBasedOnRequiredChecks } from '../../utils/visibility';
import { isEmpty } from '../../utils/helpers';
import { selectCurrentAccount, selectIsCompanyTenant } from '../../auth/AccountInfoSlice';

const navigatorclassPrefix = 'navigator';
export const navClasses = {
  fullLogoPadding: `${navigatorclassPrefix}-full-logo-padding`,
  item: `${navigatorclassPrefix}-item`,
  listItem: `${navigatorclassPrefix}-list-item`,
  nestedListItem: `${navigatorclassPrefix}-nested-list-item`,
  itemCategory: `${navigatorclassPrefix}-item-category`,
  firebase: `${navigatorclassPrefix}-firebase`,
  firebaseCenter: `${navigatorclassPrefix}-firebase-center`,
  itemActiveItem: `${navigatorclassPrefix}-item-active-item`,
  itemSubActiveItem: `${navigatorclassPrefix}-item-sub-active-item`
};

const StyledNavigatorList = styled(List)(({ theme }) => ({
  [`& .${navClasses.fullLogoPadding}`]: {
    paddingTop: 8
  },
  [`& .${navClasses.item}`]: {
    paddingTop: 1,
    paddingBottom: 1,
    color: theme.palette.common.white
  },
  [`& .${navClasses.listItem}`]: {
    paddingLeft: theme.spacing(3)
  },
  [`& .${navClasses.nestedListItem}`]: {
    paddingLeft: theme.spacing(5)
  },
  [`& .${navClasses.itemCategory}`]: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  [`& .${navClasses.firebase}`]: {
    fontSize: 20,
    color: theme.palette.common.white,
    maxHeight: '50px'
  },
  [`& .${navClasses.firebaseCenter}`]: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  [`& .${navClasses.itemActiveItem}`]: {
    color: theme.palette.common.white,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  [`& .${navClasses.itemSubActiveItem}`]: {
    color: theme.palette.common.white,
    '&.MuiBox-root': {
      borderBottom: `1px solid #F5F5F5`
    }
  }
}));

export interface NavigatorProps extends Omit<DrawerProps, 'classes'> {
  modules: SsbModule[];
  isMobile: boolean;
  isDrawerOpenDesktop: boolean;
  isPinnedMenuIcon: boolean;
  handleDrawerToggleMobile: typeof setIsDrawerOpenMobile;
  handleDrawerToggleDesktop: typeof setIsDrawerOpenDesktop;
}

export function Navigator(props: Readonly<NavigatorProps>) {
  const { isMobile, isDrawerOpenDesktop, handleDrawerToggleMobile, handleDrawerToggleDesktop, isPinnedMenuIcon, modules, ...other } = props;

  const memoizedIsMobile = useMemo(() => ({ isMobile }), [isMobile]);

  const accountInfo = useSelector(selectCurrentAccount);
  const isCompanyTenant = useSelector(selectIsCompanyTenant);

  const ldFlags = useFlags();
  const { pathname } = useLocation();

  const onListItemClick = (module: SsbModule, path?: ModuleSubPath) => {
    if (!path) {
      if (isMobile) handleDrawerToggleMobile();
    } else {
      if (isMobile) handleDrawerToggleMobile();
      if (path.param?.length) {
        const insertedParam = path.url.split(':')[0] + path.param;
        history.push(`${module.path}/${insertedParam}`);
      } else {
        history.push(`${module.path}/${path.url}`);
      }
    }
  };

  const renderNavigatorLogo = () => {
    if (!isMobile) {
      return (
        <ListItem className={clsx(navClasses.firebase, navClasses.itemCategory)}>
          {isDrawerOpenDesktop ? (
            <div className={clsx(navClasses.firebaseCenter, navClasses.fullLogoPadding)}>
              <AQLogoFull />
            </div>
          ) : (
            <AQLogo className={navClasses.firebaseCenter} />
          )}
        </ListItem>
      );
    }
    return (
      <ListItem className={clsx(navClasses.firebase, navClasses.itemCategory)}>
        <div className={clsx(navClasses.firebaseCenter, navClasses.fullLogoPadding)}>
          <AQLogoFull />
        </div>
      </ListItem>
    );
  };

  //if module has a launch darkly flag and it's turned on in launch darkly or module doesn't have the flag-render the list item navigation. Otherwise, do not render it.
  const renderModuleListItem = (module: SsbModule) => {
    if (canViewBasedOnRequiredChecks(accountInfo, ldFlags, { ...module.permissions }, isCompanyTenant)) {
      if (!module.mainPaths && isEmpty(module.dynamicallyGeneratedMenus)) {
        return (
          <NavigatorListItem key={module.name} module={module} onListItemClick={onListItemClick} pathName={pathname} accountInfo={accountInfo} />
        );
      }
      return (
        <NavigatorNestedListItem key={module.name} module={module} onListItemClick={onListItemClick} pathName={pathname} accountInfo={accountInfo} />
      );
    }
    return null;
  };

  return (
    <DrawerMobileContext.Provider value={memoizedIsMobile}>
      <DrawerComponent {...other}>
        <StyledNavigatorList disablePadding>
          {renderNavigatorLogo()}

          <React.Fragment key={'Modules'}>
            {modules.map(module => {
              //with a name, and routes
              //If modoule has roles and the user roles match that of the module or if the module doesn't have roles,
              //And if module can only be viewed by the main/admin org and the current org is the main org, or if the module can be viewed by customer org as well
              //try to render the module list item navigation
              return renderModuleListItem(module);
              //otherwise, do not render the list item navigation
            })}
          </React.Fragment>
        </StyledNavigatorList>
      </DrawerComponent>
    </DrawerMobileContext.Provider>
  );
}

const mapStateToProps = (state: any) => {
  return {
    isDrawerOpenDesktop: state.navigator.isDrawerOpenDesktop,
    isPinnedMenuIcon: state.navigator.isPinnedMenuIcon
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, never, any> | Dispatch<any>) => ({
  handleDrawerToggleDesktop: (isOpen: boolean) => dispatch(setIsDrawerOpenDesktop(isOpen)),
  handleDrawerToggleMobile: () => dispatch(setIsDrawerOpenMobile())
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);
