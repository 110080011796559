import React from 'react';
import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DimCustomer } from '../../models/DimCustomer';
import { LABELS } from '../CustomerLookup';
import DataGridWrapper from './common/DataGridWrapper';

interface CustomerResultsGridProps {
  results: DimCustomer[];
  loading: boolean;
  selectedRow: string | null;
  onRowClick: (params: any) => void;
  pageSize: number;
  setPageSize: (size: number) => void;
}

const CustomerResultsGrid: React.FC<CustomerResultsGridProps> = ({ results, loading, selectedRow, onRowClick, pageSize, setPageSize }) => {
  const columns: GridColDef[] = [
    { field: 'grmcontactid', headerName: LABELS.grmContactLabel, flex: 1, renderHeader: () => <strong>{LABELS.grmContactLabel}</strong> },
    { field: 'fullName', headerName: 'Full Name', flex: 1, renderHeader: () => <strong>Full Name</strong> },
    { field: 'sourceSystem', headerName: 'Source System', flex: 1, renderHeader: () => <strong>Source System</strong> },
    { field: 'ssid', headerName: 'SSID', flex: 1, renderHeader: () => <strong>SSID</strong> },
    { field: 'accountID', headerName: 'Account ID', flex: 1, renderHeader: () => <strong>Account ID</strong> },
    { field: 'emailPrimary', headerName: LABELS.emailPrimaryLabel, flex: 1, renderHeader: () => <strong>{LABELS.emailPrimaryLabel}</strong> },
    { field: 'phonePrimary', headerName: LABELS.phonePrimaryLabel, flex: 1, renderHeader: () => <strong>{LABELS.phonePrimaryLabel}</strong> },
    { field: 'addressPrimary', headerName: LABELS.addressPrimaryLabel, flex: 1, renderHeader: () => <strong>{LABELS.addressPrimaryLabel}</strong> }
  ];

  // Create a function that generates truly unique IDs for each row
  const getUniqueRowId = (row: DimCustomer) => {
    // Use a combination of fields to create a unique ID to prevent key conflicts
    return `${row.sourceSystem}-${row.ssid}-${row.grmcontactid || ''}`;
  };

  if (results.length === 0 && !loading) {
    return (
      <Box mt={4} border={1} borderColor="grey.300" borderRadius={4} p={2}>
        <Typography variant="body2" color="textSecondary">
          No rows found
        </Typography>
      </Box>
    );
  }

  return (
    <Box mt={4}>
      <DataGridWrapper
        data={results}
        columns={columns}
        loading={loading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        getRowId={row => getUniqueRowId(row)}
        onRowClick={onRowClick}
        selectedRow={selectedRow}
      />
    </Box>
  );
};

export default CustomerResultsGrid;
