import React from 'react';
import { Grid, MenuItem, Select, TextField, Button, Typography } from '@mui/material';

interface SearchFormProps {
  searchParams: {
    field: string;
    operator: string;
    value: string;
  };
  setSearchParams: React.Dispatch<
    React.SetStateAction<{
      field: string;
      operator: string;
      value: string;
    }>
  >;
  onSearch: () => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ searchParams, setSearchParams, onSearch }) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && searchParams.value) {
      onSearch();
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={3}>
        <Typography variant="body1">Search Field *</Typography>
        <Select fullWidth value={searchParams.field} onChange={e => setSearchParams(prev => ({ ...prev, field: e.target.value }))}>
          <MenuItem value="AccountID">AccountID</MenuItem>
          <MenuItem value="AddressPrimary">AddressPrimary</MenuItem>
          <MenuItem value="EmailPrimary">EmailPrimary</MenuItem>
          <MenuItem value="PhonePrimary">PhonePrimary</MenuItem>
          <MenuItem value="GRMCONTACTID">GRMCONTACTID</MenuItem>
          <MenuItem value="SSID">SSID</MenuItem>
          <MenuItem value="FullName">FullName</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Typography variant="body1">Operator *</Typography>
        <Select fullWidth value={searchParams.operator} onChange={e => setSearchParams(prev => ({ ...prev, operator: e.target.value }))}>
          <MenuItem value="Equals">Equals</MenuItem>
          <MenuItem value="Begins With">Begins With</MenuItem>
          <MenuItem value="Ends With">Ends With</MenuItem>
          <MenuItem value="Contains">Contains</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Typography variant="body1">Search Value *</Typography>
        <TextField
          fullWidth
          value={searchParams.value}
          onChange={e => setSearchParams(prev => ({ ...prev, value: e.target.value }))}
          onKeyDown={handleKeyDown}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button fullWidth variant="contained" color="primary" onClick={onSearch} disabled={!searchParams.value}>
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchForm;
