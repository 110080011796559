import CIModule from '../../models/module';
import FeatureFlags from '../../launchDarkly/featureFlags';
import { lazyMinLoadTime } from '../../utils/codeSplitting';
import { BarChart, Build, ImportContacts, Monitor, VerticalAlignBottom, Web, Search, Merge } from '@mui/icons-material';
import RuleIcon from '@mui/icons-material/Rule';
import Features from '../../rbac/features';
import React from 'react';
import Roles from '../../rbac/roles';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CustomerLookup from './components/CustomerLookup';

export const ToolsModule = new CIModule({
  name: 'Tools',
  path: '/reports/tools',
  modulePrefix: 'tools_',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  component: lazyMinLoadTime(() => import('./components')),
  icon: <Build />,
  permissions: {
    features: [Features.Tools],
    ldFlag: FeatureFlags.ReportingModule
  }
});

export const GRMModule = new CIModule({
  name: 'Golden Record Management',
  path: '/reports/golden-record-management',
  modulePrefix: 'grm_',
  component: lazyMinLoadTime(() => import('./components')),
  icon: <VerticalAlignBottom />,
  permissions: {
    features: [Features.GRM, Features.ContactUploader],
    ldFlag: FeatureFlags.ReportingModule
  },
  mainPaths: [
    {
      url: 'contact-uploader',
      name: 'Contact Uploader',
      icon: <ImportContacts />,
      component: lazyMinLoadTime(() => import('./components/ContactUploader')),
      permissions: {
        roles: [Roles.Administrator, Roles.GlobalAdmin],
        features: [Features.ContactUploader],
        ldFlag: FeatureFlags.ContactUploader,
        eitherRolesOrFeatures: true
      },
      exact: true
    },
    {
      url: 'customer-lookup',
      name: 'Customer Lookup',
      icon: <Search />,
      component: lazyMinLoadTime(() => import('./components/CustomerLookup')),
      permissions: {
        roles: [Roles.Administrator, Roles.GlobalAdmin],
        features: [Features.GRM],
        ldFlag: FeatureFlags.GrmCustomerLookup,
        eitherRolesOrFeatures: true
      },
      exact: true
    },
    {
      url: 'merge-unmerge',
      name: 'Merge-Unmerge',
      icon: <Merge />,
      component: lazyMinLoadTime(() => import('./components/MergeUnmerge')),
      permissions: {
        roles: [Roles.Administrator, Roles.GlobalAdmin],
        features: [Features.GRM],
        ldFlag: FeatureFlags.GrmMergeUnmerge,
        eitherRolesOrFeatures: true
      },
      exact: true
    },
    {
      url: 'data-uploader',
      name: 'Data Uploader',
      icon: <ImportContacts />,
      component: lazyMinLoadTime(() => import('./components/DataUploader')),
      permissions: {
        roles: [Roles.Administrator, Roles.GlobalAdmin],
        // features: [Features.DataUploader],
        ldFlag: FeatureFlags.DataUploader,
        eitherRolesOrFeatures: true
      },
      exact: true
    }
  ]
});

export const MonitoringModule = new CIModule({
  name: 'Monitoring',
  path: '/reports/monitoring',
  modulePrefix: 'monitoring_',
  component: lazyMinLoadTime(() => import('./components')),
  icon: <Monitor />,
  permissions: {
    features: [Features.Monitoring],
    ldFlag: FeatureFlags.ReportingModule
  }
});

export const OpsManagementModule = new CIModule({
  name: 'Ops Management',
  path: '/reports/ops-management',
  modulePrefix: 'ops-management_',
  component: lazyMinLoadTime(() => import('./components')),
  icon: <RuleIcon />,
  permissions: {
    features: [Features.OpsManagement],
    ldFlag: FeatureFlags.ReportingModule
  }
});

export const ReportsModule = new CIModule({
  name: 'Reports',
  path: '/reports/reports',
  modulePrefix: 'reports_',
  component: lazyMinLoadTime(() => import('./components')),
  icon: <BarChart />,
  permissions: {
    ldFlag: FeatureFlags.ReportingModule
  }
});

export const CustomReportsModule = new CIModule({
  name: 'Custom Reports',
  path: '/reports/custom-reports',
  modulePrefix: 'customReports_',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  component: lazyMinLoadTime(() => import('./components')),
  icon: <Web />,
  permissions: {
    ldFlag: FeatureFlags.ReportingModule
  }
});

const ReportingModule: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/customers`} component={CustomerLookup} />
      <Route path={`${path}/customer/:id`} render={() => <div>Customer Detail View</div>} />
      <Route path={path} render={() => <div>Reporting Dashboard</div>} />
    </Switch>
  );
};

export default ReportingModule;
