export enum Features {
  Tools = 'Tools',
  GRM = 'GRM',
  Monitoring = 'Monitoring',
  OpsManagement = 'Ops Management',
  ReportsViewAll = 'View Reports',
  ReportManager = 'Report Manager',
  Segmentation = 'Segmentation',
  ClientAdmin = 'Client Admin',
  ContactUploader = 'Contact Uploader',
  CustomerLookup = 'Customer Lookup',
  TicketTagging = 'Ticket Tagging',
  DataUploader = 'Data Uploader'
}

export default Features;
