import { Box, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import AssignableComponent from '../interfaces/AssignableComponent';

interface Props extends AssignableComponent, TypographyProps {}

/**
 * A styled Typography component that can be used for describing things on the page.
 * Props:
 * - component: An optional MUI prop that creates text within a specific component
 * - other props are MUI Typography specific
 * - more on typography MUI component can be found [here](https://mui.com/components/typography/)
 */
const PageContentDescription = styled(Typography)<Props>(({ theme }) => ({
  // flex: '1 1 100%',
  //Remove display and alignItems if we want to revert tooltip buttons to be next to the text again
  display: 'flex',
  alignItems: 'baseline',
  margin: theme.spacing(1),
  color: theme.palette.grey['700']
}));

// eslint-disable-next-line sonarjs/no-identical-functions
export const PageContentDescriptionBox = styled(Box)<Props>(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  margin: theme.spacing(1),
  color: theme.palette.grey['700']
}));

export default PageContentDescription;
