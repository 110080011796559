import React from 'react';

interface LogoProps {
  height?: string | number;
  width?: string | number;
  className?: string;
}

const AQLogoFull: React.FC<LogoProps> = ({ height = '35', width = '178', className = '' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 438 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      aria-label="Affinaquest Logo"
    >
      <image
        id="image0"
        width="438"
        height="86"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAbYAAABWCAYAAACjOrNGAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAABYlAAAWJQFJUiTwAAAuT0lEQVR42u2deZgcVdX/P6eqZyb7CoRVyCJLCIsmyB7CImEN
m0QFgmyyKb6guPK+L1FcQN+fC4KCsrhERHaDgEIg7KAQQJYQDAQQQgIkZCeZ7qo6vz9uVU91T3dV
dU/3zCTez/P00zXTt6tvV3fXqXPu95wDFovFYrFYLBaLxWKxWCwWi8VisVgsFoulFqQ7X0xVTwG2
qff5f5358rxDjxp7Y3fO2WKxWCyWiqjqYFX9UOskCILCyUf/af7YQT8e1tPvxWKxWCy9F6cbX+vz
QN96n/z8nMXeP59ZPJIBnNCNc7ZYLBbLeka3GDZV7QN8piv7uOOml/K+H7iuOKeCdmsI1WKxWCzr
D93lse0G7Frvkwt5f91dd8zr57iC0yIf33XUzyZ007wtFovFsp7RLYatUAjO8rzA9f2AIFBUtabn
33zDi4VCIcjlXIcWx8F13FOs12axWCyWSjTdsK1du3aU5/mH+H6A7ytBeO/7AZrByPl+4P319nkt
ruvguA6OK7Tk5DN7bnvV5j198CwWi8XS+8g1+wW8vBwujg5XBRElEMERBRE0vHdEEUcAQcr8sFfn
fbDmlVeWDnJdwREx4x2GiescClzT0wfQko6qCjAC2BoYBLQABeADYIGILKtxfy3AVsDmwEBM2ko7
sAR4VUTW9PR7tlgsPUdTw3mqKiuWr3tVhFEixmiV3ofbTtnfsftLvnn/6rvveGWAiCCO4HQ895kl
3qI9586dnu/pg2jpjKrmgCnA8cAkYNNqQ4GLReSSlP1tBHwWOALYB+hXZagPnCAiN/X0MbBYLD1D
Uz22pUvX7Y8GoxxHENHQIJXfCxKExg3juUWPLXl/zbonH/53q+s6ZlxoAB2zvfNmuS12nwuP9PRB
tHQQemf7Ad8DJgCtKU8RjHGrtr824CTgf4GPZJiCi/HeLBbLfyhNM2yq6ry3ePW5IqCBdBizikYO
JDAemaj5n4Py+INv+CtX5vs4rnm+MZBE2zkcOQNr2HoNoZf2ZYxRq+W7taTK/jYGrgaOprbowvs9
fSwsFkvP0bRQ5OLFy0f6eZkjjgwVB7M+BjiOgJR4XkAYYowZLgQ9feota999Z3U/wrGOQ7jGFoUw
ZV2r728z8++ff7enD2R3E3pGYzFrTJVYLSIvdvN8LsZ4VrV+r44TkdvK9tcG3A/sXcd0thWR+RXm
uAdwDGZ9bh0wB7hHRBZ013GyWCzNp2keW6GdT6sGQ0UFJxCC0DsLVI1BE0VFjKcWbasiCOIo/3p5
6er3Fq8Z6LhOZMTC55Vs9/Fb3GOBX/b0gewBdgEepno1l0Wqur2IfNhN85kCfIv6LpaWxv8IjeQP
qM+ogRGlxPfXCkwH/ovStblTgQ9U9TwRuaGbjpPFYmkyTZH7L168uH+h4J9oZP1G2h/dB57ieUHs
/x03zyuODf58y8s513FwXcHNOeRi265jZP9OziEn7uePn3TlgJ4+kD3ANIy3lqty2wo4rjsmEnpX
P8OoHeuhPBQ5ATi3zn35lBk24CLgG1QWnAwDrlXVw7vjWFkslubTFI/tg/ec/XItur3jhF5aFGYE
gvh9uZjEEQJg5Yr2tS89tyjn5sIxYejRiW8Xny+70jJ0PPBQTx/M7kJVBwNnZxh6pqre2g1e29kY
KX8a7cB84G3Md28oMJrYmljorX0baMuwvxXAXGAZ0AfYCBgkIhrb3xiMp5bkSfYBvqeq94pIocnH
ymKxNJmmGDYRPSMIgpxqzHAFsfuYSKRE+h+OefyhN/21H3r9HdeJrb1JyXa0bici4qBn8h9k2DAF
pftlGLc38AngwWZNJAzzfTbD0AcwwpJ5ItIePrcN4zHFQ5E7YOT8SazBhJ9/CrwrIl64v35Aufe+
CzA4w/xGYbxcu95msaznNDwU+cKT747wfD2yI8Sone+9AN8r/7+5FfJB+2MPveG6rgk9Oq6DE4Yh
nZypPuK6QlSJxIyRyScfcdMWPX0wuwNVHQCcnHG4AGc2eUpbADunjPk7cIyI/DMyagAi0i4ii0TE
j43di+qCmIgrReSrIrIwMmrh/j4UkffKxmYxamDSBPo3+VhZLJZuoOEem5/TL+IHOQ1FIUjRszJV
ReJJ1gFEisfIs5s/b0nw7uI1fd2clIQey+X+ZSHJ4bj6OeD7PX1Au4EjgJ1qGH+wqo4WkdeaNJ99
SG5HlAfOEZGVGfd3YMrjb2DWzLKyIJxDWj7dh8B/nLrWYulthGlDY1KGvRJfciinoR7bvEffH+h5
/rG+1yEGCWICEs9Xgshb86LakaWe26MPvuEJOG5YFzLyztxK27nYGMc5edKk6U0vEdaThKWkTq/x
acOBs5o4rTQj+wxQS9rBLimP/zbupWXgcYysP43bsPlvFktvYDTwVMLtz6QsxTTUsC3zPpzk+/4O
vm+Uj0HQYbCMkasUkgxvfsCa1fkP5z6/uH8UeoyHG91YGNLJObiuQ84Rcq7ZdhzZ7qObj5vU059I
kxmPKU9VKyeE61nNYHTK489mFWSoqpOyPwWeqGVyIpLHKCxXJAx7GvjvpCtAi8XSbbiYtfKkW2Ja
UcMMm6qKp3qW7wcSraF5XlC8GeNWdvMVzw/MzQt46omFi0CcXC70zHId62zxNbdcmYErGj7HOWXS
pNkbstd2DvWFj7egi41eE9gy5fFaxBjDSQ4Z5oHFtU5QRJ4DPg7cjVFQgjGSi4FLgf1FxHprFssG
QsOMwCP3/3sHnGCi78WUkCqd6j+W3AcdCdcCq/KF4EI3594sQq5EAVki7w8TtCusvTkiR++8+dKR
DxpJ+QaFqm4OfCppCMlXMeeq6h9DD6aRpIkzlmTai2FIyuPrgNX1TFJEFqjqEcBmmLSAdeHclllP
zWLZsGiYx1YoFI71PR1YonT0YgnYnlZUSQZRSNLnwXPO/8SfXUceLCogHbN+lst1rKsVt51ovS0a
5+C4Tn+nrfWonj6oTeJskuPKdwNBwuPjgd2bMK80UUYthjQtwVsxCdh1ISIqIu+IyPMi8i8R+cAa
NYtlw6Mhhm369Nk5P9AvloYawwojXsdamhdfU4vClcbIaaD+1SKiTgu/cR3Hy7lOaMRit5y55cLt
XM4hlwuNXc7BzQlOi3PaBRc83rfr76r3EEr8k3LFPgS+iVkrqoYLXBgmQDcS28ncYrH0Khpi2Hbf
dfMjfT8YUSkvzYt5a4FfIXfNC/A8/+V1hdUPA8iaoXe4Oed1J1JA5gQnpoZ0HOkQkcS2Ozw42a51
3coDevrANphjSRZVPAq8BPwuZT/7ky6jtVgslvWaLhu22bNn53w/ODOS73fI+EsNmBd7rLRWpKI+
t+2zz/arAH5/7+Q1rqszc26ZQXMl5r3FxCWRcevYdqRFapXE91pCif8ZJHtGN4hIANwBJEnhB5Kt
FJfFYrGst3RZPLJs2YjtHd+fKMVK/aa3WhArlVWs7O9QHBNEYwXPX7v2ipKdtrrXOgHnOo7T1whF
YmW3nI4EbUcqb4sw5Rvn3zfq0p9+ckMoj7QvpixWNZYDNwKIyEJVnYnx8Kpxiqp+u4aEaUsXUNUh
mKLOO2O6iLvAWuAt4GVgrojUIrCp5bVzGNXqDhiPfwQd67SReObN8PYvEVnVDcdjECb3cRxGrdsf
sza8AngdeCU8Jt3VlWK9ICxdNxJz3EbSoSCOin6/DryG+Ryb9ttW1aGY79NYzOc3EHPRvRqjMn4V
U0RhQVlFoW6ly4YtWOudpCL9jFqxo+1MRx3IMhWkU6aORO886KidSio+bDn6+Vfee/1jDzgOhztS
VhsyVhCZ6sbNbUU+jWl9sr5zLskFgWfEy1RhwpHHUN3DG4Zp3JkWtiyiqiOA/6FyhZGNU55+lqqm
VROJGJryeH/gR6pa7Yf7ioj8sML8twO+lrDfFcDFSSd2Vf021VMbVmLy4NbExm8NXIjpsLAJxqB1
2i3wdngx8kMR+XfG41QVVe2PMaRHAZMx9S/7kxydaQfeV9W7gRnAo40U1YQ5lHtiapzuj1GlVhMK
tQPvqOrNmO/2C2X7+ixwUMLLXSEiz1aYwxjMOnQ1On2GGd/bGMx3y00YdmY9J3lV7YO5IJoKHIn5
/vWl+m/bw3yfHgCuAZ6qsZhBtXkMwFQEOgdTQGE4yUKvlcCb4ff6Nkwuq1bZ90+AQWX/TjsPDAV+
qapVhWldWvi/5poXhw1qDZ5GGNlRzJhSzype5LjzGA/HPfKQw8f8tXzfXzrtjmPEcW9zyktoxQxa
cdsR86t14tJ/5udo2/2bl+67rPZ31jtQ1S0xV2LVLkDagQNF5LHYcwYA/8QU9a3GU5jcrUw/4vDH
O4fOX8DexsMisl+F+U8kuUj2YmCnJM9JVZ8Fdq3y8HvAjiKyRFVdTC3Pn9Z4vJZguhpcWY9RCY3H
CZhOBmnVW5JYi2nw+kURebML+4nmtTMmV/AQaj/fLAduBb4ceSGq+mPggoTndGpaGz5vH+CRhOcV
P8Ma398e4X6TnIS2WtNsVHUC5mLycJKNZjU8YDbwtTCPsy5UdQrwXYynWI+9WIMpwv4VEXmlwv4X
YSIZDaVLa2x9NH+A5/nbdCRcd9zHE7RLFJHxNIBCMC/v93240r4XrcrPdHOyIFJCFqX+OYdc2XYx
WTsXq1iScz/qtxYmNvqAdTNfJfkH8wymwHAREVkN3Jyy3wnAHj395jY0wnDR/wHXUvtFwEaYnnbX
1FolRlUPxnwXrqNrRg2MR3AEMFdVTwmrwdRzLFxV/V/gSeBQ6jspDsGUkHteVXft4vtaL1DVQar6
S+AxTPPeeowamPPGJ4F/qOp5YeeLWuaRU9WrMeWrdqJ+J6g/xji/oKr/E2oGmk6XDJsf6Lmer1JS
IismGAn8jlqRQaUx6IwpU7aoGEu/+eapfs6VP0W5bOW1I53w73hVkpgyEtcVWh3njO44iM0gDP+l
5eTdUCXUcAvJ+V5C/Y08LdX5LnA+9Z8EHExX7+lZ0jJUtY+qXgTcjlnzaCT9gF8DX6/1iWE49JfA
xSQXyM7K1sDM0IvZYFHVkcA9GIFXWn5oVlqAy4HvZ031CY3gFRjRWqNowUQkfhau/TaVug3btb/4
xyjfDyZ1SraOyfs9LyhVRZYmaH+wbnXhpsQD7AbXu64s65D0O5Xk/TEDF9s2CsmJ/+/7j2zb7IPY
JI4luXnnEowKshIvYa6UkzhIVdfXY9Mb2Rf4SgP2I5i+dQdlGPtT4BKy9earhxzGyE7J+oTQa70S
s57WyFq0WwG/pfeHw+tCVbcAZmLaNjWDLwA/zjj2BEzh9Ea3NRPM9+JjTXqPReqeuO/pBb6vEk+4
9kq2O4ofx8ORsdsDx0/b+Y2k1/jR5UfOz7nOw7mcFJOz3Vws7BhL2M7Ft4t1Jp1BrjinNfsgNppw
0fiLKcNmAwsrPSAia4E/pDx/EFb63yhagB/RuBNBK3Bp+D1I4vckF3eOsxKjfnwFo8hsz/i8VuD/
qerwjOMvJHu/wFoZS+3dLXo9qjoMUzloXIbhazHr3bcA12OWHZ7ErEcmkQPOV9VjUuYyhGwGcBFm
Lfa3GFX2I5h1yiSWA1NE5KmmHczYm62Zq6c/vZHveYd1UjjG71XKFJKl923q/iLLIrmbc69BOMrU
hKwq7y9VTJbUmHROvPzy+Rd/6UsfzfpD7g0cAGyfMuZXKcfvZsx6T9LV/LRQ+p92cmzHCCwqhY3T
Fn5XYRaQs9CCUVwlkVQEuSmy+QwMpbOSaxUmcf4BOgpBb41Rt00kfe1kV4zX9pdqA0TkMVU9GfNZ
l6/LKcaQ/RG4K5zDhxhpvYs5zntiDMW+KfMZgwldX5I0YVXdHfhvsoViV4fH5zaMsf0AE7YcGR6f
qaQrbtd7wvDgpaQ3612ECSneALwnIuti+8hhfodnAOdhlM/V+LGqPiEi1X5H00hu9Psv4DvAfcD7
0TkoXIsdjqln+yU6n78WAieJyINl/6/0m+3qeaA+w5bv336I+DKqonw/ug+i+3gx42hMsODYU3Z5
kM+lv5b2yz/cUujzL2DbYk4b6QbNGD9BHNlS/KVHYq5w1hfOJvnq/23MCbMqoUJvJslV/TfClOq6
KmU+C6leZ/JZYJuE514IJIacY2xHcgh1JUbCXk0Wn6k9TjfwAkbi3em9qOrlmJPHVSSncTiYkNBf
EsYgIneq6vcw61mRcXoH+CHm4mdtlacuB15T1RsxIoVrSS5CfQIJhk1V+2JO0FnW1O7HhG2fr3Bx
9hRwk6r+ALO+dy71CyjWB6KLiyTuAk6r0B0egHCd/W1M2PiOcPzmVfa1DXAK5rMqITSyhybMYynw
mUrpFGGBiPcxMvzbgf/FpAeAKUp/iIhUyiveh84XQmnngXcxAriqF8w1h06mT1fHKwRnl6ggK6of
YxVGympEBgV+klXS/O1vH7bSdeQ6N6wL6TqdlZEdysnO9SRdV2hz3VMuv/zuZvUjayiquj1GlZbE
leEXKY0bSC9CfHaYIlAVEQlEZHmlW4Y5rK723Ar7SksQDoCVCfuoKQepSdyPScGo+MMUEV9EfkO2
kNpBGVWJ3wN+EW7PAD4mIj9LMGrx+XihPH4yJsxVje1VNamp7KHAfqRzGXCwiPwz6RwgIgsxqQvH
YLy5DY4wNeQyks/DM4Cp1YxaOaG0/3CSoyRnhyHHcvqSvK7/NPBchjksFpFzMSHNezCpRQuqjF1R
x3nAB1YknUtqNmwDnEc/5vvBhGrls4JYZf/AD8qUkorvB0u8tf7dtbxmm7bNcF3aXae0L1vVztrl
CsocB/ftO2K7Wt9rD/FfJIdyVgF3ZtzXfZg8uCR2wYSiLF1nIXBOxt5uN2K6eyexMcneMFC8Wr4I
OBM4I+tJsGwf/6C6GClifKV/hsb3dNJDkL8DvpvxoizqxnBnuO9UI70ecgDJHTdeAM6vtQpLaNx+
nzBka4zxK6cPyWHINTXmWF4IfCq8SOlWajZsgR98xve1LTJUgVfaHdur0JrGL81h++vpX/5ETaWu
vvy9vRbmHPdOx61SEDlMCXCqGDfHcVraTMy+V6Oqm2CqgiTxBDA3y/7COHwWI2il/43hahHJ1Asw
rERxTYahmZSrIrJKRH5dVoWmVm5PebyaxzaG9M7ubwDfCPMsa+XPmPSBDY0TSa7g8V0RWVrnvn9N
std2dIX/Kcmtr/ZQ1c2yTiC8MOmR0mg1GbYLLni8b8Hzz+wwUn6YjN05HFnSviaU+3teEATqpq3n
VD5IjvMbN+e0F5OwO4Ucy9rcRI+F247jnjBjxvzeLhU+hXQxxnU1XjX9lnQF3MSUMJMlnQJmcb8W
HiU97JL5RNIA3iS5iHa1MNUepKcc/EJEFtUzqfD7/gNq68beq1HVjTDVWKqxmK7pAl4gWUy1f4Uw
dzvJKtvNgRmqOqEJ7a8aSk2GbXDrqqP8QAcFQZh0HYQJ2EFHMnYQ/b94ix4LCHx9ruAOebqW14zo
t1Hbva4jb5n8tNBbK98u/5/bse26jHT9VQf39AGvRijtPill2FvArBp3PQ9TYiuJQZjEYEv9PJ1B
XVrOu6R3BG9Kb0FVlbDKxRaqOlpVx2JyxZKoprZLy70KMGtFdROWuvp1M45FD7EDRrxVjSeyhmwr
ISIFzPmiGsMxRYzjz/kQeDFl1wdgytPdqapTQtFQr6MGVaRKENx3mgZBsXJ/pwr+Tkd1/051IQUc
ce64oE7Z/VlnTShc9eMnfovjXNJZ0l+qhixJDShNE/gcvVcdOQnzZU/iIWpcSBcRT1VnkNwhAOAk
Vf2fXiLAWB9JS4ivxCpMlf0k6i1pJRhDtSMmIXYMxuPaFFOUeSi1qw1bVFUqRAw+nnZs6vXWypjJ
hlHYHIwcPun4t6rqtC6+RlqEaic6G787MQrYJPph1ugOB9pVNUpreRx4oQvh04aR2bB9/by/7ewX
ZM/quWuk5a6tk3z7dZX2PXu25lZ+8PJkfPmK7xUuOvaEnZ+oPFu5LedwETh9itX+45X/Y8WXo2LI
8bw3RCbffvvL2xxzzA5v9PSBr8AXM3we9wBbq9ZcI/cfmKvmpJPkxpgcqxt7+kCsp7xd6xNERFW1
njWnioQ1JnfAVK05EmPYsiZWZ542Zi0mztYpz3mMxvAyJp+rO8OzzSJNzBYZjmZS6TjehonwZK05
2oap/H8gJoy9VFVnY9Zr7+mOVkiVyO6xaXCC78uAmnLXQiNn8sm4+8vfO7CTOmb2bO1TWPOv6TnH
ubDg+a7nMWvGNc/+bNjmA7592GGl3p205eY7nt4tjhxb9MwknidXxZPr8N5aJNBppCSadjeqOork
/JGItGoiXeU8VZ1pe2HVRZrnVY0u596F3tkkTHL0fnRj3le4TpOWSJ1JUJNGeCHwIhuGYesN76HT
5yYieVU9CXMRvWWN+8thev59JrzNVdWfYXQBXW6fUwuZwhzTL/jrMN8PTorqPpaXz/I9Lb0vpgIU
x6hf8EpEI6oqD856Y38Krz2FyNdV1Q1QAtV+gQbfXPT68ud/+ZOn9o4vUp511oQCLc71xZy1SETi
xqr/u6bslhvfDoUluZzQ6jqn3n77s0O68yBn4OtZP4smsydVJN2W3kkoQrgVs/Z6AN2fzNxGusx/
eQNfr+ZUhl5KbxCyVZT2i8iLmALsaWvzaYwFrgYeD/Nzu41MJ9MVq72DfU83j1rRlOeuldyCcuOm
eL4+35r3izk7L7741rCnHnvr5y057gIZpwGoKhooqoQ33VZ974HLL330kh9f8HhxgXLAkLZ7XVfe
SMtdM0avtFCy6zqIIyMHtAw6oDsPchJhz7W0hOzuQjDFUi3rAao6HtO26Bh67sIoSwPNRlWqhw2n
CklvUBVWjdiJyDOYprDfx1Sy6Qq7YcQmu3bXG8v0Y/A8/8woRy3wK3hn5Tlr0X1QNHw3fPX/Jq8B
mDPnnfHeWncWjnxBVfuqqjFqHQYNjRSVqq1+wLe8gfmHvvP1h3YCmDp1XD7nyu8jg1aaqB1uh5X/
HcfpyHuLbeMGZ3bXAc7AVKqXv+kJJoehUUsvRlVHY+pAZv2sFFOK7G7gV5hqJV/DXMiciam6/gOS
5f6dCBtopgnCGhl2a3hTyuit1Pk8p87n1hu6biSJqksRWSYiF2HSOU7DiNfqzZMcA/w+bGnUdFLX
2M6ddtsOnhfsH61bBdGaFeF9UBRmhKINhUhAYh77wO+Tn/H666/38dYNvDhf8M7Pt/t9Oryz+H25
kTMhSx/dTdWbc9EFf7ussGLdD1tb267z/cJ54jpDnNI1tGpqyKKQJBSV7PHwrDfHTjxo60yJzs0i
/JB7W3L0EEzLipr7cFm6lf8DPpoyRjENSK/B1A98OykHMux3Vk/rnYUkG9gdG/GGw2WJXRuxrwo4
JCdLV6MP9XnLaQW7n8Z08Ggmf8sySETewnQSuF5VB2IU1odg0jx2IrlaSZxxwDcwncGbSqphK+T1
HHEpFYoEHRX8HVFj7MSIRDQQCMc4joLrzPriFyeO8Qv6MyXYJQhUSrwzhUAVDei4hQNKjB60aKD/
HbS1HD7rgTenHXTQVveLcFy5ArKSGrJk2whKBourp9OY/lldYTLZr7i7k8+p6iV1VomwNBlV3Y70
8PVSTNPTW+KV4FNoo75Q36skf48nqarTlbyskN1JLtTcFVqoL2dwa+rz2NJK3b0uIl9r0nutm1Dl
eD9wf6yrwBGYqv77kW5TTg87ijRVTJJ4pXHK0bcP8VWP9CuUyYpCkl5ZV2yv2D3bhCsnHz6mj4Nz
L8quQRBIZKgCNeFGY8zM33GvLYiMWjSm47GPuX7+0ZdfeLdvp9JaZU1Ho1Bleedt1xVckalPP/10
t7QpT+AL9I5YezkjgE/39CQsVZlC8gnkQ0yLkBk1GDUwIfF6DNtzKY9vQ3JNxFRCb+3zXdhFmvq0
D8ntXqpR7/t6nvTyVb1BUFaVsID22yJyFR1FsNMUsJuRnurQZRKtq7LuOM9ztym2h4klWwflydiB
FD23KGF7k00HsOMum07J5/0SQxUEWuqtxYxd0UuLGbNy7y0IdMjCt1YeNvqjwxk0pE8lWT9OmNtG
6KnFt0OvbUspbHoM2VuqNBRV/RhGxZZEHjie9Ar9tXIJpu1DEmep6o02YbtXkpZjNEtE/lrHfuvt
3jwb0/U76Xxynqo+04ValmMxSr16WZnyeGv4GpkrI6lqC8ZTqYc5GK+6WqrEVuH7Tavf2SsIK508
rqpHYxK1BycMHwO8lPB4Fs8+0SGo+kU8/vibWgvr/GlOECAOaJQAXZ6z5lRI1A7Hjv/EFh1eV2Sc
ALRyyBGFIBpPZACj/5Wuw+XzPgteW8b43bfo3JetrEcb1dfezrjppptunTp1ahZlV6P5rwxjbhOR
mY1+YVUdSbph2w1zoruvB46NJZk0z+KlTHuJoar9qP8k/SgmQX2bhDGfxlT9ua2OuQ0CvkvXks0X
YtYck06Ih2M6EGTl5HrnJCLvqerfSQ4pf0dVHwnLidVM6OWeDPylnmogqrpRra8tInNV9VWS04bS
Qr5pFz9R2Lhq8nd1V3dtYVff1707Kx7LVZAxlWTs/62tLtvuuHHMA6sUdgyNVtyTCzRmyCgLUUaq
STP+zQXLEKFzm5qy0GPODfu4FUOXTvgYE8dtd1BaGauGo6pbkL5GUsAs2DaDmzE/9DTO7s7jYmkY
9awVnUftCbkAhGuxaR6iA1wRrg/WytdJ73qRNseVpH/nDw3Tb1IJq9xf1JU5kf77Hgf8JPQM6+Hz
mPqas1Q1rUN3+fvbFZitqnvW8bppEaa0KNDylMcHkVLtpqphCwp6su8HOS/MXfNCw+WVVe+P1tW8
mJHzPGXsziMYMrRP6XpZuYEquxH7f4lBi4xhbL0tUGXN6jxvvr68tLJ/rnw7XHOLGpXGkrVd121T
yfeE9P900q/05mGuhBtOeBV2a4ahB3R3YqUlE2lJygemNY+No6qHYtRqXeFK0k9YmwEPqeq+WarD
h0Wafwd8qwHHDNJ/TwOBX1Rpwhmf15aYyhwjuzifWRjVahInAXep6jZZd6qqLar6bUy3iRaMknSW
qh6b8fmHhHMbB9ynqlOzVvMPo0FjU4YlCmdEZBnJXQbagOOS9lHRsJ146IxBfiH4rMlF62ga6sW8
tbBaf1iBxPRli5K0ReDjn9i8aIjQCtL+IPLiOhKz4waQoidn4gcalHpvkRGc98J7Zj2tTDDidvRi
Mx5b5Knloor/ReHJkfPnL+22KgCqOpz0Kv4Af2xyaas/kJ5cO4Tel45gSRdr7ARcFUqzqxIajvMx
34UhXZlQWK0iS4RhBOak+RNV3a6SN6Kqm4QFgB8h228lK1nk7UdiZO3bx8UbYTeEwaFxeIDstRST
jtlKzAVF2prSJ4HHVPVCVd2smpFR1SGqekR43P4HYwAiNgb+oKoXq2rVhHlVnYLJj4wuvPtjOjNc
r6qJ3R9UdQTGmCatr60iW4m1l1MeP1tVDyh/L6o6QFV3qLjGtnyNd7wjDIuvpWmgJlfNKZX4R+tr
KoKoCWFvtc1ARo4eatSRGoYWo9Bj0ZBpRxiygtELYutycbFJFL6M/vfWWytY9sE6Rmzav+JaGiI4
YRoADjiUbbuyFb7/KeA6uoeDSc8/Wg78qcnzeB54GFNdIImpqvoNWz+yV/FAhjEnAuNU9UbM5/wm
Zu1iEEaVNh5Tz68hOWYh38es06UlUbdi1phPA15U1QUYIUULxgvahebUUvwLZi0wLdx4NEbhNydc
L8qH72kPjKijkUrmWRhDcmLKuM2BHwFfBZ5R1WcxbY8CzJrrtpiSeEleZB/gYmCUqp4WNrstoqpT
gWuBcm+/BfgccKSq3ocJOz+FycVzMGKQPTFLF2le7D0ZBURPhse7GoPDeTynqq9jEt5HYNZ5WzsZ
tuPHXz14lRecpqEIxBgGDVWQGqofO7ekCRwxCkmU3ffaCnEE9TrWzyJjFnlagVIUjhSNX1AWpgwi
UUmJIrJkfc7LBzw35x0OnbJdcj5bMam8UzcA15HcKXSDYQuvtLKEfB7CdBxuGiKyTlWvI92wjcD8
6DakXljrNSLyvKrOxMj+k9iFBngWNcxrkaqeTnYPcCDmhJh1HSfq8FxXWS0RWaKqV5Kt9c1Q4KDw
lsQ/MV7NmDrnpKp6NqYrQ1r7HzAthw4huUlpEj4wp4JROwO4glIvr5xhGBFQvalAHvDTjGNvwxSK
SFovbsGI3HYr+//bnUKRy3JyUOAF4zvy1WJ1IMsEIiX3YSiytS3Hbntt1bFOFlM2BkG5vD8WbgxK
jZmWrLfFPDpiisrwOfNffh/P88OGolIMN3YUQQ7FI7HtMrHJ3oveXNXIK9dq7I0JE6VxdQOSWbNw
O9mKyp4TNkK19B6+RjYBUC3cQRcLFovI3RhRRTO+v7+hxn6EFfg16c00s1LAdFRISyVIJBTfHI/x
gpqJD3wH+HmFxxaR3vS2KyjGcGZ9j49iLvDroaWTYfMLeobna1s1w9VxX9m4TdhjC/r1b+kQelAm
FAm0RAmpQbmgpExkEsTClWXjIyO57IN1vPTPd0OD5oQKyNC4lW07oeHLlRo3J3D0lCZ+qFHOywWk
hzEWAvc2cy4RYY5allY4u5D9qtrSDYjIK5gr2nrzwsp5GmMsG2GQfoVZG2tk/uWjmFy5LqXmhLL3
00kXuqThA98Tkb804s2JyAJMCPReOve7awTtwHTgu5XKqonIXcBhNC9SdC/wrawVR8I5fgVTbKBW
3BLDdsD4y0f7HpNL1Y8dApGo0ohX2pKm2MJGA5h44MjSkGI8hy3MY4v/v8Mb01dR+UYQBK9GJbaC
uNdXvp+45+cHPPX420bxGCogI/WjU7YdKSbj26HXdvKiRavS+kp1hb1JD2sAXFEeJmgy15KQDxLi
AF/LqoyydA/hyegAutbvTIE/Y0QTaxs0L09E/ogJc3fVO1JMrcsjRGR5g+b3j/D91lu1vh3jlX63
EfOJzesdjHE5rwtzq8Q84JMiUtGolR2XnTHHe0XWnWfgKuAoEanp+yUiczFrtrXm4JUatrVrc2f7
QSB+lZY0UfmsknJaUXktP2DUtsPYZvTQ5KLGnb20duCqnLDfqedOuMyRwkRVvV5B40nZQaX9xLy8
1+Z/wLuLVuHkSvPZcmW5bZEasnw7l3M26dMn18yOteeQ3oNpFdDwhOwkROQlsnU43hfTzt7SixCR
xzEXTT+i9hPAfEwC71QRWdykue0LfBOodf8eRv35WeBcEWnkiRYRmY3p+nwLtXm984CjReSyZlyA
iogvIldi1tsuw3RkqAcPeAWjjtxXRB7J+PqrROTz4bH5NfWHftsxUYBjgQvqrTgjIvdgEufvI7sn
mytege8+5vJB5Nw5IowRQuGFUyoQKXasDkUlHSIM879zvrwHnzxsDO3tPvm8TyHvkW8PyLd75PM+
+fD/0XZ7IXgrX/AvPG7q2JvjVxLTp7/Y2hYsObxQ8H5dyAfDC3nf7LPdoxA9N+8Xt/N5s/8DDx3D
p6ftUi4OKalEUiIsKevC7cATg4f13TvpqqZeVPVTpBcIXYoph9SMUETS3HYgm8jgH2HIpJb39XB4
JZplHkMxhaGrsS48PjWtBYTJtPslDGkH/py0rqmqh5F8YdLp2GSc2xF0VqHF+buIpBXMjfa1FcaD
OxxTgf0jlIa+FWNgHsGsp90VSs6j5/fBCFKq5bcuFZG6KtGEUvDJmPyj/aleEX4pRvV5K+YzWVe2
n0Ukqy6PE5HM1U1U1cV0Hz8F4y1VquoSAE8ANwIzyj3HDJ/hn+r9TavqJpgLl6MwFwkjqb6c4QOv
YVSw9wB/62pJvDBnbyLmszsQo86s9vp54FmMovEO4NFGFTtW1b6YupzHYNSSYzACH8F8r98N3/vL
wP3FCU4Yc8U0VH9X0pKmWEaLopEoPlYyBjYeMYArr59CW78WCu0+7aExM0bJo5APQoPkkW/3NJ8P
rnPa3G9Nnjymqnjh+9+cNfzDdv/yfD6YWmj3cu3tPoWCMXKFdo/2mLEs5D369mnl0isOYfDQPsX5
FetIxstrlaUCRO/DEfkwwD9w8OB+Tzbiw7BYepLwZDAC6IcROrzXaM+nznkJxuh+BCPbFky04g3g
3ykXGA01bBXmNTKc10CMQXsPmN+oMGgDjt0ATCeFjTGfKxjRx1LgtWbXdg0vPrfGqDP7YIzpCkzo
9O2wP193HQvBKCML5RcOOYBJk6bnli8ITha3tCVNp/qPQXQf1oiMjdltjy0YMKiNQrHgsZEwBkFc
BakQsMBRueiQgdveIvsnW/Nv/eCgpdOn67T29+6dGfjB5aq6icbKblEW7ly2bC3PPvUOBxwyOmbQ
SnPbyr25Mg+uX4vTegbmisNiWa8J1zTe6Ol5VJiXYvLq3uzpuVSY14Lw1isJoxXP9+DrLwOW9fRx
COeiVBEoOQC5VZvtoBJMLFE6JnXJ9sse8wKOOG5sBYl/qbRf4Ga3b27i/oeOuTHNqEVMny7Bj34x
+U9r/WCvINDbg6DC2l2s1Na9d803bWyi9bNcrMpITjrqRca3i122hSAIjrnpphcb2creYrFYLN2I
AzBrzlkvbL314HNa23KLI4WjV1IDskMdWV4z0vcCdthpE8ZsN6xYob+jLJYxQKLyjguf2X2fj0zd
a6+t6sq9+dVvj3ntg7x3vAbBNA1YGC+aHBlRFF7711Lmz1tqakHGFJFuLlRMRopIt3NtyXVrvVdf
f23pSVOnjus2d9pisVgsjaW4SDzz4VOv+8iWQ3bbeMTA+wMVjby3wNOS5qFRA9EgpoY86vixJcWJ
O8pniUfAzQSF/Xb9xBZdLhF1881T/etv/fSMdev8/QOVW7XMiAaqrFvncdft88J8NonlsEmHAtLt
2HZdIQg0v/CtFVc//493J+6482b39PSHYrFYLJb6KVE/3fbgSW9POmrPw7YZM/hTba3u0qJRCw1Z
PBQZSf0HD+nDHvt+pHPtx0DfV9Uv3Hjrxp8ZN36rVxs56VvvO3F++9KBJ3t+cGqgwWqNVzQJlEfu
f518u1+arJ0r5quFRZLNdj7vv/3q3KUnjxwz7OyJh2yzqKc/EIvFYrF0jU6y3unTx+XvevjU2yYd
tNm2G23a/w9AYEKPWpawbUKRBx/xUYYN7xsrcYUq3OoGOmHMdsN/NX16c0pD/WXOlA/vfuTU3wQF
f8dAuUcDNFp7W758HbPunh+GIKUYhnRMvho51wFBl76/9trHHntr/E7jN212wWGLxWKxdBNV+7H9
5NqpHzz83FnTtt9x4xNbWp0lpcnaxltraclx+FHbh2FHCFQXa+B/adPNBpy4xehh9SYW1sSsOWf9
+/38kKMDlS8Evi6LQpO3/vFF/CAIRSFRsraD4whr1uRf/+dT75x01a/6n52UbmCxWCyW9Q8n6UER
0dvvP/nGnT/xkd2Hb9Tv9qCkGokybtdN2H6nTYxARPibOLLfRpsMvKLeLPN6mTt3av7Jl8/5pYi3
XxDo/arKC88t5pWXlhRrQ7qugziw5L01t9535ysTJ+y11Q3TpzcmedBisVgs6yHTp6tz9AF/mLb9
pj9+a9SQy3TU0B/qzFvnahAEyzzPO7O31BEcy/TWMcN+eMHWAy97//wz7tSINavb33ll7tJ62y1Y
LJZegKou0mQydYm2WEr40il3fnTCqJ/fMn70z4NVK9bNUtVdeotRi7N1vx98bJ9xv7x38aJV3ltv
Lr/l6fv/Pbqn52SxWLqGNWyWpjH9zJn9fjT9kUmqmlb7sEcZNerSwb+77pn9eqPhtVgstWMNm8Vi
sVg2KKxhs2TB6fouLBaLxWLpPVjDZrFYLJYNil69RmaxWCxlPAkMSXjc5qVaLBaLxWKxWCwWi8Vi
sVgsFovFYrFYLBaLxWKxWCz/yfx/0e8RQXMPoTYAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDMt
MzBUMTg6NTI6MTArMDA6MDCsBDylAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTAzLTMwVDE4OjUy
OjEwKzAwOjAw3VmEGQAAAABJRU5ErkJggg=="
      />
    </svg>
  );
};

export default AQLogoFull;
