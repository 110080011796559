import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { CompositeRecord } from '../../models/CompositeRecord';
import { LABELS } from '../CustomerLookup';
import DataGridWrapper from './common/DataGridWrapper';

interface CompositeRecordGridProps {
  data: CompositeRecord[];
  loading: boolean;
  pageSize: number;
  setPageSize: (size: number) => void;
}

const CompositeRecordGrid: React.FC<CompositeRecordGridProps> = ({ data, loading, pageSize, setPageSize }) => {
  const columns: GridColDef[] = [
    { field: 'sourceSystem_Winner', headerName: 'Source System Winner', flex: 1, renderHeader: () => <strong>Source System Winner</strong> },
    { field: 'ssiD_Winner', headerName: 'SSID Winner', flex: 1, renderHeader: () => <strong>SSID Winner</strong> },
    { field: 'fullName', headerName: 'Full Name', flex: 1, renderHeader: () => <strong>Full Name</strong> },
    { field: 'gender', headerName: 'Gender', flex: 1, renderHeader: () => <strong>Gender</strong> },
    { field: 'emailPrimary', headerName: LABELS.emailPrimaryLabel, flex: 1, renderHeader: () => <strong>{LABELS.emailPrimaryLabel}</strong> },
    { field: 'phonePrimary', headerName: LABELS.phonePrimaryLabel, flex: 1, renderHeader: () => <strong>{LABELS.phonePrimaryLabel}</strong> },
    { field: 'addressPrimary', headerName: LABELS.addressPrimaryLabel, flex: 1, renderHeader: () => <strong>{LABELS.addressPrimaryLabel}</strong> },
    { field: 'companyName', headerName: 'Company Name', flex: 1, renderHeader: () => <strong>Company Name</strong> }
  ];

  return (
    <DataGridWrapper
      data={data}
      columns={columns}
      loading={loading}
      pageSize={pageSize}
      setPageSize={setPageSize}
      getRowId={row => row.ssiD_Winner}
    />
  );
};

export default CompositeRecordGrid;
