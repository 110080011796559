import React, { useState } from 'react';
import FormHeader from '../../../../components/FormHeader';
import { Box, Typography } from '@mui/material';
import { GrmIds } from '../../models/GRMIds';
import { CompositeRecord } from '../../models/CompositeRecord';
import { MatchedRecord } from '../../models/MatchedRecord';
import GrmIdsGrid from './GrmIdsGrid';
import CompositeRecordGrid from './CompositeRecordGrid';
import MatchedRecordsGrid from './MatchedRecordsGrid';
import { DEFAULT_PAGE_SIZE } from '../CustomerLookup';

interface GrmResultsContainerProps {
  grmIds: GrmIds[];
  compositeRecord: CompositeRecord[];
  matchedRecords: MatchedRecord[];
  loadingGrmIds: boolean;
  loadingCompositeRecord: boolean;
  loadingMatchedRecords: boolean;
}

const GrmResultsContainer: React.FC<GrmResultsContainerProps> = ({
  grmIds,
  compositeRecord,
  matchedRecords,
  loadingGrmIds,
  loadingCompositeRecord,
  loadingMatchedRecords
}) => {
  const [grmIdsPageSize, setGrmIdsPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [compositeRecordPageSize, setCompositeRecordPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [matchedRecordsPageSize, setMatchedRecordsPageSize] = useState(DEFAULT_PAGE_SIZE);

  return (
    <Box>
      <FormHeader id="grm-results-container" title="GRM results and contact details for the selected record above" />

      {/* GRM IDs Grid */}
      <Box mt={2}>
        <Typography variant="h6" gutterBottom>
          Unique Identifiers
        </Typography>
        <GrmIdsGrid data={grmIds} loading={loadingGrmIds} pageSize={grmIdsPageSize} setPageSize={setGrmIdsPageSize} />
      </Box>

      {/* Composite Record Grid */}
      <Box mt={2}>
        <Typography variant="h6" gutterBottom>
          Golden Record
        </Typography>
        <CompositeRecordGrid
          data={compositeRecord}
          loading={loadingCompositeRecord}
          pageSize={compositeRecordPageSize}
          setPageSize={setCompositeRecordPageSize}
        />
      </Box>

      {/* Matched Records Grid */}
      <Box mt={2}>
        <Typography variant="h6" gutterBottom>
          All Matched Records
        </Typography>
        <MatchedRecordsGrid
          data={matchedRecords}
          loading={loadingMatchedRecords}
          pageSize={matchedRecordsPageSize}
          setPageSize={setMatchedRecordsPageSize}
        />
      </Box>
    </Box>
  );
};

export default GrmResultsContainer;
