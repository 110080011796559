import axios, { AxiosInstance } from 'axios';
import { setHttpHeaders } from '../utils/api';
import { tenantIdHeaderKey } from '../utils/org';
import { findDefaultScopeByUrl } from '../authConfig';
import msalInstance, { handleTokenAcquisitionFailure } from '../msal-instance';
import { getCurrentTenantIdFromSessionStorage } from '../utils/sessionStorage';

////
//Create an HttpClient "wrapper" around axios instance
//This axios instance of this class can then be used accross different api calls globally
//HttpClient also gets a function reference to getAccessTokenSilently from Auth0 so tokens are added to the request outside of components
//The function gets called with help of axios interceptors that run on every request
////

/**
 * A wrapper around the axios instance
 *
 * The axios instance within the HttpClient can be used across different api calls globally
 *
 * HttpClient gets a function reference to `getAccessTokenSilently` from Auth0 and `getOrgId` from the global utils directory.
 *
 * It's then able to retreive the access token silently from Auth0 from outside of components and use axios intereceptors to add authorization on every request, along with the org_id header by using the getOrgId function reference.
 */
class HttpClient {
  axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_SSB_HUB_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    this.axiosInstance.interceptors.request.use(
      async config => {
        // this tells Axios to not inject headers when we are calling the legacy API hosted on ssbintel.com. The API there will reject requests if additional headers are included on the request.
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
          const defaultScope = findDefaultScopeByUrl(config.baseURL as string);
          if (!defaultScope) {
            throw new Error('No default scope found for this endpoint');
          }
          const silentRequest = {
            scopes: defaultScope, // Replace with your API scopes
            account: accounts[0] // If there are multiple accounts, you need to choose which one to use
          };

          try {
            const silentResponse = await msalInstance.acquireTokenSilent(silentRequest);
            const token = silentResponse.accessToken;
            return setHttpHeaders(config, {
              'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_SUB_KEY,
              Authorization: `Bearer ${token}`,
              [tenantIdHeaderKey]: config.headers?.[tenantIdHeaderKey] || getCurrentTenantIdFromSessionStorage()
            }); //a more streamlined process to set headers. Use either an already provided org_id or use the one from localstorage of the logged in user
          } catch (error) {
            // Fallback to an interactive method if silent acquisition fails
            await handleTokenAcquisitionFailure();
            console.error('Token acquisition failed, falling back to interactive method', error);
          }
        } else {
          await handleTokenAcquisitionFailure();
        }
      },
      error => {
        Promise.reject(error);
      }
    );
  }
}

export default new HttpClient();
