import HttpClient from '../../../shared/HttpClient';
import { setApiConfigVersion } from '../../../utils/api';
import { DimCustomer } from '../models/DimCustomer';
import { CompositeRecord } from '../models/CompositeRecord';
import { GrmIds } from '../models/GRMIds';
import { MatchedRecord } from '../models/MatchedRecord';

const version = '1.0';
const config = setApiConfigVersion(version);
const baseUrl = process.env.REACT_APP_INTEGRATION_METADATA_BASE_URL;

////
// Get the global axios instance created by HttpClient class
////
const axios = HttpClient.axiosInstance;

export default class CustomerLookupApi {
  static async getDimCustomerRecords(tenantId: string, searchField: string, searchOperator: string, searchValue: string) {
    const payload = {
      TenantId: tenantId,
      SearchField: searchField,
      SearchOperator: searchOperator,
      SearchValue: searchValue
    };
    const url = `/api/customer-lookup/dim-customer/${tenantId}`;
    const result = await axios.post(url, payload, { ...config, baseURL: baseUrl });
    return result.data as DimCustomer[];
  }

  static async getCompositeRecords(tenantId: string, grmContactId: string) {
    const payload = {
      tenantId: tenantId,
      GRMContactId: grmContactId
    };
    const url = `/api/customer-lookup/composite-record/${tenantId}`;
    const result = await axios.post(url, payload, { ...config, baseURL: baseUrl });
    return result.data as CompositeRecord[];
  }

  static async getMatchedRecords(tenantId: string, grmContactId: string) {
    const payload = { TenantId: tenantId, GRMContactId: grmContactId };
    const url = `/api/customer-lookup/matched/${tenantId}`;
    const result = await axios.post(url, payload, { ...config, baseURL: baseUrl });
    return result.data as MatchedRecord[];
  }

  static async getGrmIds(tenantId: string, grmContactId: string) {
    const payload = { TenantId: tenantId, GRMContactId: grmContactId };
    const url = `/api/customer-lookup/grm/${tenantId}`;
    const result = await axios.post(url, payload, { ...config, baseURL: baseUrl });
    return result.data as GrmIds[];
  }
}
