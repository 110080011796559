import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { MatchedRecord } from '../../models/MatchedRecord';
import { LABELS } from '../CustomerLookup';
import DataGridWrapper from './common/DataGridWrapper';

interface MatchedRecordsGridProps {
  data: MatchedRecord[];
  loading: boolean;
  pageSize: number;
  setPageSize: (size: number) => void;
}

const MatchedRecordsGrid: React.FC<MatchedRecordsGridProps> = ({ data, loading, pageSize, setPageSize }) => {
  const columns: GridColDef[] = [
    { field: 'isPrimary', headerName: 'Is Primary', flex: 1, renderHeader: () => <strong>Is Primary</strong> },
    { field: 'sourceSystem', headerName: 'Source System', flex: 1, renderHeader: () => <strong>Source System</strong> },
    { field: 'ssid', headerName: 'SSID', flex: 1, renderHeader: () => <strong>SSID</strong> },
    { field: 'dimCustomerId', headerName: 'Dim Customer ID', flex: 1, renderHeader: () => <strong>Dim Customer ID</strong> },
    { field: 'grmContactId', headerName: LABELS.grmContactLabel, flex: 1, renderHeader: () => <strong>{LABELS.grmContactLabel}</strong> },
    { field: 'fullName', headerName: 'Full Name', flex: 1, renderHeader: () => <strong>Full Name</strong> },
    { field: 'emailPrimary', headerName: LABELS.emailPrimaryLabel, flex: 1, renderHeader: () => <strong>{LABELS.emailPrimaryLabel}</strong> },
    { field: 'phonePrimary', headerName: LABELS.phonePrimaryLabel, flex: 1, renderHeader: () => <strong>{LABELS.phonePrimaryLabel}</strong> },
    { field: 'addressPrimary', headerName: LABELS.addressPrimaryLabel, flex: 1, renderHeader: () => <strong>{LABELS.addressPrimaryLabel}</strong> },
    { field: 'gender', headerName: 'Gender', flex: 1, renderHeader: () => <strong>Gender</strong> },
    { field: 'companyName', headerName: 'Company Name', flex: 1, renderHeader: () => <strong>Company Name</strong> },
    { field: 'customerType', headerName: 'Customer Type', flex: 1, renderHeader: () => <strong>Customer Type</strong> },
    { field: 'customer_Matchkey', headerName: 'Customer Matchkey', flex: 1, renderHeader: () => <strong>Customer Matchkey</strong> }
  ];

  // Create a function that generates truly unique IDs for each row
  const getUniqueRowId = (row: MatchedRecord) => {
    // Use a combination of fields to create a unique ID to prevent key conflicts
    return `${row.sourceSystem}-${row.ssid}-${row.dimCustomerId || ''}`;
  };

  return (
    <DataGridWrapper
      data={data}
      columns={columns}
      loading={loading}
      pageSize={pageSize}
      setPageSize={setPageSize}
      getRowId={getUniqueRowId} // Use our new unique ID function instead of just row.ssid
    />
  );
};

export default MatchedRecordsGrid;
