import { Box, Button, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import theme from '../theme';
import AQALogoDark from '../components/logos/AQALogoDark';
import { useConfirmUserMutation, UserConfirmationPayload, UserInvitationConfirmationResponse } from '../auth/UserApiSlice';
import Form from '../components/Form';
import { ErrorToast } from '../components/Toaster';
import { useMsal } from '@azure/msal-react';
import PasswordHelperText from './PasswordHelperText';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type AccountConfirmationFields = {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
};

type AccountConfirmationLoading = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

type AccountConfirmationProps = UserInvitationConfirmationResponse & AccountConfirmationLoading;

export const AccountConfirmation: React.FunctionComponent<AccountConfirmationProps> = ({ ...props }: AccountConfirmationProps) => {
  const [accountFormInputs, setAccountFormInputs] = React.useState<AccountConfirmationFields>({
    firstName: props.firstName ?? '',
    lastName: props.lastName ?? '',
    password: '',
    confirmPassword: ''
  });
  const [enableSubmit, setEnableSubmit] = React.useState(false);
  const [confirmUser, { isLoading }] = useConfirmUserMutation();
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
  const [passwordFieldFocused, setPasswordFieldFocused] = React.useState(false);
  const [passwordIsValid, setPasswordIsValid] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const { instance } = useMsal();

  const { firstName, lastName, password, confirmPassword } = accountFormInputs;
  const { email, userExists, confirmationId, setIsLoading } = props;

  const disableContinue = () => {
    return !userExists && (firstName === '' || lastName === '' || password === '' || confirmPassword === '' || !enableSubmit);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    if (confirmPasswordError) {
      validateConfirmPassword();
    }
    if (id === 'confirmPassword' && value === password) {
      setConfirmPasswordError(false);
    }
    setAccountFormInputs(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const sendUserConfirmation = async () => {
    setIsLoading(true);
    const payload: UserConfirmationPayload = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      confirmationId: confirmationId,
      password: !userExists ? password : crypto.randomUUID.toString()
    };
    confirmUser(payload)
      .unwrap()
      .then(async () => {
        await instance.loginRedirect();
        await instance.handleRedirectPromise();
      })
      .catch(err => ErrorToast('Failed to confirm user invitation', err));
  };

  const handleSubmit = () => {
    sendUserConfirmation();
  };

  /*~~~~~~ START PASSWORD VALIDATION ~~~~~~*/

  useEffect(() => {
    if (passwordIsValid && confirmPassword === password) {
      setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
  }, [confirmPassword, password, passwordIsValid]);

  const validateConfirmPassword = () => {
    if (confirmPassword.length) {
      password !== confirmPassword ? setConfirmPasswordError(true) : setConfirmPasswordError(false);
    }
  };

  const handleIsPasswordValid = (isValid: boolean) => {
    setPasswordIsValid(isValid);
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  /*~~~~~~  END PASSWORD VALIDATION ~~~~~~*/

  return (
    <Box height="100vh" display="flex" flexDirection="column" justifyContent="center">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <AQALogoDark />
        <Typography fontSize="20px" p={2}>
          {email}
        </Typography>
        <Form id="AccountConfirmationForm" onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box>
              <Typography fontSize="32px" fontWeight={theme.typography.fontWeightBold} textAlign="center" width="100%">
                Welcome to Central Intelligence
              </Typography>
              <Typography fontSize="20px" textAlign="center" width="100%">
                {!userExists ? 'Please enter your name and create a password here' : 'An account already exists! Please confirm below'}
              </Typography>
            </Box>
            <Box width="30rem" m="auto" display="flex" flexDirection="column" gap={2}>
              <TextField
                id="firstName"
                label="First Name"
                variant="outlined"
                fullWidth
                value={firstName}
                disabled={userExists}
                onChange={handleChange}
              />
              <TextField
                id="lastName"
                label="Last Name"
                variant="outlined"
                fullWidth
                value={lastName}
                disabled={userExists}
                onChange={handleChange}
              />
              {userExists ? null : (
                <FormControl>
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    onFocus={() => setPasswordFieldFocused(true)}
                    onBlur={() => setPasswordFieldFocused(false)}
                    fullWidth
                    value={password}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {passwordFieldFocused && <PasswordHelperText password={password} isPasswordValid={handleIsPasswordValid} />}
                </FormControl>
              )}
              {userExists ? null : (
                <TextField
                  id="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  value={confirmPassword}
                  onChange={handleChange}
                  onFocus={validateConfirmPassword}
                  onBlur={validateConfirmPassword}
                  error={confirmPasswordError}
                  helperText={confirmPasswordError ? 'Passwords do not match' : null}
                />
              )}
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" type="submit" disabled={disableContinue() || isLoading}>
                  Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default AccountConfirmation;
